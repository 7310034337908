/* Global constants */
import { isEmpty } from 'lodash';
import routes from '../routes';
import engageThemeColors from '../style/engage/color-export.module.scss';

const {
  EXCLUDED_FEATURES,
  PWA_REENGAGE_DELAY,
  STORE_MAX_AGE,
  STORE_EXPIRY_ENABLED,
  SUBDOMAIN_EXCEPTIONS,
  DEVICES_RENDERING_MENU_PWA_WIDGETS,
  ADDITIONAL_DISCOUNT_CODE,
  ENABLE_SUBSCRIPTION_MODULE,
  REPEAT_ORDERS_COUNT,
  TRANSITION_TYPE,
  DEFFERED_PING_SERVER_IN_SECONDS,
  CASHFREE_ENVIRONMENT,
} = '_env' in window && window._env;

export const repeatOrdersCount = REPEAT_ORDERS_COUNT;

/* Logic to derive flags which control enabling of premium features */
const premiumFeaturesList = ['PWA', 'UPSELL', 'CASHCARD'];

const excludedFeatures =
  EXCLUDED_FEATURES === 'null' || EXCLUDED_FEATURES === undefined // If EXCLUDED_FEATURES env var is set to 'null' or not set at all
    ? []
    : EXCLUDED_FEATURES.split(',');
export const premiumFeatureFlags = {};
premiumFeaturesList.forEach(
  (feature) =>
    (premiumFeatureFlags[feature] = excludedFeatures.indexOf(feature) === -1 ? true : false),
);

/* Extract device names from the environment variable to control the enabling of the pwa widgets on menu screen on particular devices */
export const devicesRenderingMenuPWAWidgetsList =
  DEVICES_RENDERING_MENU_PWA_WIDGETS === 'null' || DEVICES_RENDERING_MENU_PWA_WIDGETS === undefined // If DEVICES_RENDERING_MENU_PWA_WIDGETS env var is set to 'null' or not set at all
    ? []
    : DEVICES_RENDERING_MENU_PWA_WIDGETS.split(',');

export const appName = 'client';

export const primaryDomain = 'fudr';

export const fudrThemeColor = '#547aa8';

export const engagePrimaryThemeColor = engageThemeColors.primaryThemeColor;

export const regex = {
  successCodes: '^[2][0-9][0-9]$',
  nameValidator: `^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$`,
  addressValidator: `^(?![ -.&,_'":?!/])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!/]{2})[a-zA-Z0-9- .#@&,_'":.?!/]+$`,
  vehicleNumberValidator: '^[a-zA-Z]?[0-9]{3,4}$',
  emailValidator:
    '^[a-zA-Z0-9!#$&*=%?~](?!.*\\.\\.)[a-zA-Z0-9!#$&*=%?~._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,63}$',
  noEmojiValidator: "^[a-zA-Z0-9\\s,)(&'+-\\./;]*$",
  amountValidator: '^(\\d+)(\\.\\d{1,2})?$',
  integerValidator: '^[0-9]\\d*$',
  iosDeviceValidator: '/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i',
};

export const imagePatternDivider = 3;

export const renderServiceModeDescription = (serviceMode) => {
  let description = {
    SP: 'Self pickup your order from restaurant',
    DI: `Scan the QR code to place order`,
    DT: 'Get your order delivered at your car',
    RO: `Get your order delivered at your room`,
    DL: 'Get your order delivered at your place',
  };
  return description[serviceMode];
};

export const renderServiceType = ({ serviceTypeConfig, serviceMode }) => {
  let type = {
    SP: 'Self Pickup',
    DI: `Dine In`,
    DT: 'Drive Through/Car',
    RO: `Room`,
    DL: 'Delivery',
  };
  if (!isEmpty(serviceTypeConfig)) {
    type = { ...type, ...serviceTypeConfig };
  }
  return type[serviceMode];
};

export const restaurantOperatingMode = {
  READ: 'READ',
  DEACTIVE: 'DEACTIVE',
  STOPPED: 'STOPPED',
  CLOSED: 'CLOSED',
  NOTOPEN: 'NOT_OPEN',
  ACTIVE: 'ACTIVE',
};

export const description = {
  viewMore: 'view more',
  viewLess: 'view less',
  truncatedLinesLimit: 3,
};

export const assistanceServices = {
  requestedAssistanceTimeInSeconds: 60,
};

export const toastMessageTime = 3000;

export const scanQRConfig = {
  scanQRTimeout: 400,
  nonFudrQRTimeout: 1000,
};

export const paymentTypes = {
  PREPAYMENT: 'PRE_PAYMENT',
  POSTPAYMENT: 'POST_PAYMENT',
  NETBANKING: 'net_banking',
  loyaltyPaymentTypes: { CASHCARD: 'cc' },
};

export const cashCardPaymentCombinationsConfig = {
  cashCardOnly: 'CASH_CARD_ONLY',
  cashCardPlusPrePaid: 'CASH_CARD_PLUS_PREPAID',
  cashCardPlusPostPaid: 'CASH_CARD_PLUS_POSTPAID',
  cashCardRechargeAndOrderPayment: 'CASH_CARD_RECHARGE_PLUS_ORDER_PAYMENT',
};

export const highAvailabilityServerAddress = 'https://www.google.com';

// List of API endpoints and other third-party endpoints excluded from internet disconnectivity error handling.
export const internetDisconnectivityHandledAPIEndpointBlacklist = [
  '/payments/initiatePayment',
  '/payments/paymentResponse',
  '/payments/verifyPayment',
  '/orders/status',
];

export const internetErrorResponseStoreDispatchDelay = 500; // in ms

export const intervalToResendOtpInSec = 30000;

export const resendOTPLimits = 3;

export const phoneNumberMaxLength = 10;

export const countryCodeMaxLength = 4;

export const apiResponseCodes = {
  SUCCESS: [200, 201, 202, 203, 204, 205, 206],
  forbidden: 403,
  NOT_FOUND: [400, 422],
};

export const serverErrorCodes = {
  invalidMethodParams: 'INVALID_METHOD_PARAMS',
  badRequest: 'BAD_REQUEST',
  unknownServerError: 'UNKNOWN_SERVER_ERR',
  notFound: 'NOT_FOUND',
};

export const customErrorCodes = {
  contextlessURLHit: 'CONTEXTLESS_URL_HIT',
  default: 'DEFAULT',
};

export const alignment = {
  center: 'center',
  left: 'left',
  right: 'right',
};

export const restaurantEntityTypes = {
  restaurant: 'RS',
  restaurantGroup: 'RG',
};

export const pwaFeatureEnabled = premiumFeatureFlags.PWA;

export const buttonSize = {
  long: 'long',
  short: 'short',
};

export const cardStyles = {
  full: 'full',
  tablet: 'tablet',
};

export const pwaInstallPopupDelay = 2000;

export const installPopupReengageDelay = PWA_REENGAGE_DELAY && JSON.parse(PWA_REENGAGE_DELAY);

export const pwaInstallUserChoices = {
  accepted: 'accepted',
  dismissed: 'dismissed',
};

export const paymentPortalGuestID = 'FUDR';

export const browserList = {
  safari: 'safari',
  chrome: 'chrome',
  iOSSafari: 'ios',
};

export const operatingPlatformsList = {
  mobile: { ios: 'iOS', ipadOS: 'iPadOS', android: 'Android OS' },
  desktop: {
    macOS: 'Mac OS',
    linux: 'Linux',
    windows: 'Windows',
  },
};

export const socialPlatformAppCodes = {
  instagram: 'social.instagram.profile',
  facebook: 'social.facebook.profile',
};

export const topPosition = 0;

export const upsellFeatureEnabled = premiumFeatureFlags.UPSELL;

export const upsellStackMountTransitionDelay = 300; // in ms

export const upsellCardsTransitionDelay = 400; // in ms

export const noOffersToastDuration = 3000;

export const inHouseTableTypes = ['DI', 'RO'];

export const analyticsVendorType = { GTM: 'GTM' };

export const acceptableImageTypes = ['jpeg', 'png', 'gif', 'svg', 'jpg'];

export const storageTypes = {
  sessionStorage: 'sessionstorage',
  localStorage: 'localstorage',
};

export const storageKeys = {
  originRoute: 'originRoute',
  outletOutOfRange: 'outletOutOfRange',
  pagesInNative: 'pagesInNative',
};

export const paymentGateway = {
  cashfree: 'C',
  paytm: 'P',
};

export const userActivityTrackingDebounceTime = 5000; // in ms

export const storeMaxAge = STORE_MAX_AGE && JSON.parse(STORE_MAX_AGE); // in ms

export const storeExpiryEnabled = STORE_EXPIRY_ENABLED && JSON.parse(STORE_EXPIRY_ENABLED);

export const currencies = {
  INR: { text: 'Rs.', symbol: '₹' },
};

export const exceptionComponentPageTypes = {
  halfSize: 'HalfSize',
  fullSize: 'FullSize',
};

export const permissionStatus = {
  allow: 'allow',
  block: 'block',
};

export const touchGestureDirections = {
  all: 'DIRECTION_ALL',
  horizontal: 'DIRECTION_HORIZONTAL',
  vertical: 'DIRECTION_VERTICAL',
  none: 'DIRECTION_NONE',
  left: 'DIRECTION_LEFT',
  right: 'DIRECTION_RIGHT',
  up: 'DIRECTION_UP',
  down: 'DIRECTION_DOWN',
};

export const UPSELL = 'UPS';

export const recommendedCategory = {
  name: 'Recommended',
};

export const priceprecision = 2;

export const upsellSearchErrorCase = {
  dealAlreadyTakenError: 'DEAL_TKN_ERR',
  minCartValueError: 'MIN_VAL_ERR',
  noDealLeftError: 'NO_DEAL_ERR',
  dealUnavailableError: 'DEAL_UNA_ERR',
};

export const geoLocationConfig = {
  maxDistanceInKm: 2,
  maxDifferenceBetweenTimeStamps: 120, // in minutes
};

export const cashCardUnitPointMagnitude = 1;

export const cashCardUnitPointMonetaryFactor = 1;

export const commonRechargeAmounts = [500, 1000, 2000, 4000, 10000];

export const popularRechargeValue = 2000;

export const maxCashCardRechargeAmount = 20000;

export const cashCardFeatureEnabled = premiumFeatureFlags.CASHCARD;

export const cashCardScreens = {
  rechargeOngoing: 'recharge-ongoing',
  rechargeConfirmation: 'recharge-confirm',
  cardDetail: 'card-detail',
};

export const cashCardTransactionTypes = {
  recharge: 'RECH',
  order: 'ORDR',
  refund: 'REFU',
  reward: 'REW',
  expired: 'EXP',
};

export const transactionCategory = {
  C: { name: 'credit', styleClass: 'green', prefix: '+' },
  D: { name: 'debit', styleClass: 'red', prefix: '-' },
};

export const cashCardAPIParams = {
  guestCashCards: {
    pageSize: 10,
    sortType: 'cashCard.title,ASC',
  },
  guestCashCardTransactions: {
    pageSize: 10,
    sortType: 'initiateDate,DESC',
  },
};

export const suggestedAmountGrowthFactor = 2;

export const bannerStyles = {
  bgColors: { red: '#f0340c' },
};

export const authInitiatorFeatures = {
  offer: 'offer',
  upsell: 'upsell',
  cashCardRecharge: 'cash-card-recharge',
};

export const restaurantDataDepthParams = {
  config: 'con',
  tables: 'loc',
  delivery: 'del',
  additionalCharges: 'rac',
  assistnaceService: 'ras',
  workhours: 'rwh',
  serviceTypes: 'rst',
  phoneNumbers: 'rpn',
  notificationEmails: 'rne',
  default: 'all',
};
export const orderStatusConfig = {
  UNATTENDED: 'UNATTENDED',
  CONFIRMED: 'CONFIRMED',
  SETTLED: 'SETTLED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  READY: 'READY',
  PARTIALCONFIRM: 'PARTIALCONFIRM',
  OUTFORDELIVERY: 'OUTFORDELIVERY',
  ORDERDELIVERED: 'ORDERDELIVERED',
  DISPATCHED: 'DISPATCHED',
  DELIVERED: 'DELIVERED',
};

export const restaurantCashCardInjectablePositionOnMenu = 1;

export const orderModes = {
  selfPickup: 'SP',
  carOrder: 'DT',
  delivery: 'DL',
  dineIn: 'DI',
  room: 'RO',
};

export const pickupTimeConfig = {
  pickupTimesKey: 'pickupTimes',
  options: [
    {
      name: 'At restaurant',
      value: 0,
    },
    {
      name: '10 min',
      value: 10,
    },
    {
      name: '15 min',
      value: 15,
    },
    {
      name: '20 min',
      value: 20,
    },
    {
      name: '30 min',
      value: 30,
    },
    {
      name: '45 min',
      value: 45,
    },
  ],
};

export const whitelabelSubdomainExceptions =
  SUBDOMAIN_EXCEPTIONS === 'null' || SUBDOMAIN_EXCEPTIONS === undefined // If SUBDOMAIN_EXCEPTIONS env var is set to 'null' or not set at all
    ? []
    : SUBDOMAIN_EXCEPTIONS.split(',');

export const offerTypesConfig = {
  bogo: 'IO', // Buy one get one free
  discountOnBill: 'QD',
  freeItem: 'IF',
  directDiscount: 'DD',
};

export const bogoOfferItemType = {
  buy: 'BUY',
  get: 'GET',
};

export const menuEntityTypesConfig = {
  menuCategory: 'MC',
  menuItem: 'MI',
};

export const menuCategoryTypes = {
  main: 'MC',
  sub: 'SC',
};

export const offerApplicabilityTypesConfig = {
  anyItem: 'AI',
  sameItem: 'SI',
  sameCategory: 'SC',
};

export const loyaltyTypesConfig = {
  cashCard: 'cc',
};

export const refundTypesConfig = {
  prepaid: 'PRE_PAYMENT',
  loyaltyRefundTypes: { CASHCARD: 'cc' },
};

export const refundStatusConfig = {
  initial: 'INITIAL',
  success: 'SUCCESS',
  pending: 'PENDING',
  failed: 'FAILED',
};

export const emailOpTypesConfig = {
  ADD: 'ADD',
  VERIFY: 'VERIFY',
};

export const updateProfileFieldTypesConfig = {
  email: 'email',
  name: 'name',
  guestVehicle: 'guestVehicle',
};

export const trueCallerResponseTimerInterval = 2000;

export const onlinePaymentTypesConfig = {
  orderPayment: { code: 'PAYMENT', queryParam: 'P' },
  cashCardRecharge: { code: 'RECH', queryParam: 'R' },
  orderPaymentPlusCashCardRecharge: { code: 'RECH_PLUS_PAYMENT', queryParam: 'RP' },
  membershipPayment: { code: 'MEMBR', queryParam: 'MP' },
  payEarnPayment: { code: 'PAY_EARN', queryParam: 'PE' },
};

export const trueCallerRetryCount = 3;

export const transactionStatusConfig = {
  initial: 'INITIAL',
  success: 'SUCCESS',
  pending: 'PENDING',
  failed: 'FAILED',
};

export const transactionSubStatusConfig = {
  userDropped: 'User_Dropped',
  invalid: 'Invalid',
};

export const featureSpecificAPIErrorCodes = {
  noRestaurantCashCard: 'NO_CC_FOUND',
  otpInvalid: 'OTP_INVALID',
};

export const processingOrderTimerDuration = 3; // in seconds

export const progressBarStyles = {
  bgColors: { blue: 'blue' },
  size: { tiny: 'tiny', small: 'small', medium: 'medium', large: 'large', big: 'big' },
};

export const cashCardRechargeAmountInputMaxLength = 5;

export const restaurantPremiumFeaturesKeys = {
  pwa: 'PWA',
  upsell: 'UPSELL',
  cashCard: 'CASHCARD',
};

export const approxPWAInstallTime = 15000; // in ms

export const pwaStartPath = '/fudr-web-client/';

export const fallbackButtonOnGetAppDisplayTime = 20000; // in ms

export const safariFallbackButtonOnGetAppDisplayTime = 8000; // in ms

export const addressType = {
  route: 'route',
  subLocalityLevelThird: 'sublocality_level_3',
  subLocalityLevelSecond: 'sublocality_level_2',
  subLocalityLevelFirst: 'sublocality_level_1',
  locality: 'locality',
  administrativeAreaLevelSecond: 'administrative_area_level_2',
  administrativeAreaLevelFirst: 'administrative_area_level_1',
  plus_code: 'plus_code',
};

export const defaultMapState = {
  center: {
    lat: 28.6139,
    lng: 77.209,
  },
  minZoom: 5,
  maxZoom: 16,
  maxDistanceBtwCoordinates: 200,
  distanceInMeter: 1000,
};

export const additionalDiscountCodeConfig = {
  code: ADDITIONAL_DISCOUNT_CODE || '',
  stub: 'EXTRA DISCOUNT',
};

export const subscriptionModuleEnabled = JSON.parse(ENABLE_SUBSCRIPTION_MODULE) || false;

export const screenOrientation = window.screen // Currently, ScreenOrientation Web API is not supported in iOS. See https://developer.mozilla.org/en-US/docs/Web/API/Screen.
  ? (window.screen.orientation || {}).type ||
    window.screen.mozOrientation ||
    window.screen.msOrientation
  : undefined;

export const isScreenOrientationPortrait = ['portrait-primary', 'portrait-secondary'].includes(
  screenOrientation,
);

export const deals = {
  defaultPageSize: 50,
  defaultSortType: 'startTime,ASC',
};

export const dealStatus = {
  activeFuture: 'AF',
  active: 'AC',
  expired: 'EX',
};

export const dealCategory = {
  newCustomers: 'NC',
  campaign: 'CM',
};

export const offerTypeCode = {
  DirectDiscount: 'DD',
  ItemOffer: 'IO',
  QuantityDiscount: 'QD',
  ItemFree: 'IF',
  ComboOffer: 'CO',
};

export const dealTypes = {
  streak: 'STREAK',
};

export const couponMaskedRoutes = {
  deal: 'd',
  coupon: 'cpn',
};

export const feedbackMaskedRoutes = {
  feedback: 'fd',
};

export const timeOfDayStrings = {
  morning: '04:00',
  afternoon: '12:00',
  evening: '17:00',
  night: '22:00',
};

export const greetingTimeSalutationsMap = new Map([
  [timeOfDayStrings.morning, 'Good Morning'],
  [timeOfDayStrings.afternoon, 'Good Afternoon'],
  [timeOfDayStrings.evening, 'Good Evening'],
  [timeOfDayStrings.night, 'Hello'],
]);

export const sessionTimeoutPurgeInclusionRoutes = [
  routes.containers.cart,
  routes.containers.restaurant,
  routes.containers.payment,
  routes.containers.offers,
  routes.containers.confirmation,
  routes.containers.orders,
  routes.containers.aboutOutlet,
  routes.containers.cashCards,
  routes.containers.account,
];

export const defaulRewardCouponExpirationInDays = 15;

export const transitionType = TRANSITION_TYPE || 'fade';

export const defferedPingServerInSeconds = DEFFERED_PING_SERVER_IN_SECONDS;

export const cashfreeEnvironment = CASHFREE_ENVIRONMENT;

export const userAccountStatus = {
  blocked: 'BLOCKED',
  rewardsBlocked: 'BLOCKED_REWARDS',
};

export const engageSpecificRoutes = [
  routes.containers.deals,
  routes.containers.deal,
  routes.containers.coupon,
  routes.containers.feedback,
  feedbackMaskedRoutes.feedback,
  couponMaskedRoutes.coupon,
  couponMaskedRoutes.deal,
  routes.containers.restaurant,
  routes.containers.welcome,
];

export const tableBookingErrorCodes = {
  duplicateReservation: 'DUPLICATE_RESERVATION_ERR',
  maxReservation: 'MAX_RESERVATION_ERR',
};

export const subscribePassMembershipStatusConfig = {
  active: 'ACTIVE',
};

export const reservationStatusConfig = {
  booked: 'BOOKED',
  fulfilled: 'FULFILLED',
  cancelled: 'CANCELLED',
  checkedIn: 'CHECKED_IN',
  pending: 'PENDING',
};
